import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import FocusTrap from 'primevue/focustrap'
import moment from 'moment'
import 'moment/dist/locale/de.js'
import primeVue from 'primevue/config'
import 'primeicons/primeicons.css'
import Tooltip from 'primevue/tooltip'

import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import { LimbachTheme } from '@/theme'
import i18n from '@/i18n'

moment.locale('de')

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(primeVue, LimbachTheme)
  .use(ToastService)
  .use(ConfirmationService)
app.directive('focustrap', FocusTrap)
app.directive('tooltip', Tooltip)

router.isReady().then(() => app.mount('#app'))
