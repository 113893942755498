<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { t } from '@/i18n'
import '@fortawesome/fontawesome-free/css/all.css'


const route = useRoute()
const routerLinkClass = (checkedRoute: string) => {
  if (route.path.toLowerCase() !== checkedRoute.toLowerCase()) return 'p-button p-button-text'
  return 'p-button'
}


const formattedBuildTimestamp = ref(__BUILD_TIMESTAMP__)
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <header class="flex justify-between px-8 py-4 h-16">
      <RouterLink to="/">
        <img
          alt="Limbach Logo"
          src="@/assets/Limbach.png"
        >
      </RouterLink>
      <div class="ml-auto mr-4">
        Last build: {{ formattedBuildTimestamp }}
      </div>
      <Avatar
        icon="pi pi-user"
        shape="circle"
      />
    </header>
    <div class="flex flex-grow">
      <nav class="w-[8.25rem] min-w-[6.25rem] flex flex-col items-center py-4">
        <RouterLink
          class="nav-item"
          to="/regelgruppen"
        >
          <Button
            :class="routerLinkClass('/regelgruppen')"
            :label="t('navigation.RegelAnlegen')"
            icon="fa-solid fa-rectangle-list"
            icon-pos="top"
          />
        </RouterLink>
        <hr class="custom-hr">

        <RouterLink
          class="nav-item"
          to="/analysen"
        >
          <Button
            :class="routerLinkClass('/analysen')"
            :label="t('navigation.Analysen')"
            icon="fa-solid fa-atom"
            icon-pos="top"
          />
        </RouterLink>
        <RouterLink
          class="nav-item"
          to="/gueltigkeiten"
        >
          <Button
            :class="routerLinkClass('/gueltigkeiten')"
            :label="t('navigation.Gueltigkeiten')"
            icon="fa-solid fa-house-medical-flag"
            icon-pos="top"
          />
        </RouterLink>
        <RouterLink
          class="nav-item"
          to="/probengueter"
        >
          <Button
            :class="routerLinkClass('/probengueter')"
            :label="t('navigation.Probengueter')"
            icon="fa-solid fa-vial"
            icon-pos="top"
          />
        </RouterLink>
        <RouterLink
          class="nav-item"
          to="/textbausteine"
        >
          <Button
            :class="routerLinkClass('/textbausteine')"
            :label="t('navigation.Textbausteine')"
            icon="fa-solid fa-comments"
            icon-pos="top"
          />
        </RouterLink>

        <RouterLink
          class="nav-item"
          to="/keime"
        >
          <Button
            :class="routerLinkClass('/keime')"
            :label="t('navigation.Keime')"
            icon="fa-solid fa-virus-covid"
            icon-pos="top"
          />
        </RouterLink>

        <RouterLink
          class="nav-item"
          to="/keimgruppen"
        >
          <Button
            :class="routerLinkClass('/keimgruppen')"
            :label="t('navigation.Keimgruppen')"
            icon="fa-solid fa-viruses"
            icon-pos="top"
          />
        </RouterLink>

        <RouterLink
          class="nav-item"
          to="/antibiotika"
        >
          <Button
            :class="routerLinkClass('/antibiotika')"
            :label="t('navigation.Antibiotika')"
            icon="fa-solid fa-prescription-bottle-medical"
            icon-pos="top"
          />
        </RouterLink>
      </nav>
      <main class="content">
        <slot />
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-item {
  @apply m-4 flex justify-center items-center w-full;
  margin-top: 2px;
}

.nav-item > .p-button {
  @apply flex justify-center items-center;
  width: 100%;
  border-radius: 0;
  height: 77px;
}

.content {
  background: var(--background-gradient);
  border-color: var(--p-content-border-color, #dfe7ef);
  border-top-left-radius: var(--p-border-radius-xl);
  @apply flex-grow border-t border-l border-solid;
  overflow: hidden;
  position: relative;
}

.custom-hr {
  border: none;
  height: 2px;
  background-color: #991355;
  margin: 5px 0 17px 0;
  width: 80%;

}


</style>
