import { preset } from '@/theme/preset'

export const LimbachTheme = {
  theme: {
    preset: preset,
    options: {
      prefix: 'p',
      darkModeSelector: 'dark-mode',
      cssLayer: false
    }
  }
}
