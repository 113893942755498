import { defineStore } from 'pinia'
import { ref } from 'vue'
import { t } from '@/i18n'
import { useSessionStorage } from '@vueuse/core'

export const useUserStore = defineStore('user', () => {
  const userName = useSessionStorage('user', '')
  const isLoggedIn = ref(userName.value != '')

  async function login(name: string, password: string): Promise<void> {
    if (!name || !password) {
      console.error('username or password ist empty')
      throw new Error(t('userStore.missingFields'))
    }

    if (name !== 'admin' || password !== 'asd3Sf32SDFs43%§$') {
      console.error('wrong credentials')
      throw new Error(t('userStore.wrongCredentials'))
    }

    userName.value = name
    isLoggedIn.value = true
  }

  return { isLoggedIn, userName, login }
})
