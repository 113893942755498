<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'

const route = useRoute()
const layout = computed(() => {
  return route.meta.layout || DefaultLayout
})
</script>

<template>
  <div class="h-screen">
    <component :is="layout">
      <RouterView />
    </component>
  </div>
  <Toast />
  <ConfirmDialog />
</template>
